//创建项目的弹框
<template>
  <el-dialog
    class="show_create_project"
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
    title="创建作品"
    center
  >
    <!-- iframe组件,用于播放项目 -->
    <div class="content ub ub-f1">
      <iframe
        v-if="showDialog"
        :src="iframeSrc"
        scrolling="no"
        allowFullScreen="true"
        class="show_project"
        frameborder="0"
        id="scratch3Id"
      ></iframe>
      <div class="ub ub-ac ub-pc ub-ver iframe-menu">
        <div class="menu-item" @click="saveScratchWork">保存作品</div>
        <div class="menu-item" @click="close">关闭窗口</div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },

    handleClose() {
      this.close();
    },

    //保存作品
    saveScratchWork() {
      let scratch3Id = document.getElementById("scratch3Id");
      console.log(this.projectInfo);
      let projectId =
        this.projectInfo && this.projectInfo.projectId
          ? this.projectInfo.projectId
          : "";
      scratch3Id.contentWindow.postMessage(
        { projectId: projectId ? projectId : "" },
        `*`
      );
    },
  },
  computed: {},
  props: ["showDialog", "iframeSrc", "projectInfo"],
};
</script>
<style lang="scss" scoped>
.show_create_project {
  margin: 10px 0 10px 10px;

  ::v-deep .el-dialog {
    margin-top: 0px !important;
    padding: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: initial;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      height: 100%;
      padding: 0;

      .content {
        height: 100%;

        .dv_project,
        .show_project {
          width: 100%;
          height: 100%;
          background-color: #fff;
        }

        .show_project ::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }

        .iframe-menu {
          padding: 0 0 0 30px;
          .menu-item {
            background-color: #4169e1;
            font-size: 18px;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            writing-mode: vertical-rl;
            padding: 25px 10px;
            color: #fff;
            margin: 30px 0;
            letter-spacing: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>